export const BASE_API_DEV   = "http://localhost:4000"
// export const BASE_API_DEV   = "https://dev.api.isms.impuls.id"

export const BASE_API_PROD  = "https://pptqnh.api.isms.impuls.id"

export const SEKOLAH_KODE = "pptqnh"

export const APP_TIMEZONE = "Asia/Jakarta"

// export const MIDTRANS_CLIENT_KEY = 'SB-Mid-client-zC6FgeXfmYafDomV'
export const MIDTRANS_CLIENT_KEY = 'Mid-client-3MoQFpnmg26ON78n'