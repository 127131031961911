import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../context';
import { Button, Header } from 'semantic-ui-react';
import { MIDTRANS_CLIENT_KEY } from '../../main.config';
import { isEmpty } from 'lodash';
import Media from "react-media";

const Pembayaran = () => {
  const { model, user } = useContext(UserContext);

  useEffect(() => {    
    const midtransScriptUrl = 'https://app.midtrans.com/snap/snap.js';
    // const midtransScriptUrl = 'https://app.sandbox.midtrans.com/snap/snap.js';
  
    let scriptTag = document.createElement('script');
    scriptTag.src = midtransScriptUrl;
  
    const myMidtransClientKey = MIDTRANS_CLIENT_KEY;
    scriptTag.setAttribute('data-client-key', myMidtransClientKey);
  
    document.body.appendChild(scriptTag);
  
    return () => {
      document.body.removeChild(scriptTag);
    }
  }, []);


  const IS_PPDB = model === 'ppdb'
  if (!IS_PPDB) return "";

  if (user?.paid === false && !isEmpty(user.token)) {
    return (
      <div style={{ maxHeight: '50px', height: '50px', display: 'flex', background: "#fe9a06", justifyContent: "center", padding: ".6em 0", alignItems: "center"}}>
        <div>
          <Media query={{ maxWidth: 768 }}>
            {(v) => v
              ? ""
              : <Header size='small' color='white'>Mohon lakukan pembayaran biaya Penerimaan Murid Baru (PMB)</Header>}
          </Media>
        </div>
        <div style={{ padding: "0 1em"}}>
          <Button color='green' compact onClick={() => {
            if (window?.snap?.pay) {
              return window.snap.pay(user.token)
            }
          }}>Pembayaran biaya PMB</Button>
        </div>
      </div>
    )
  }

  return ""  
}

export default Pembayaran